import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/Button"
import {
  Body,
  Header1,
  Header1Accent,
  Header2,
  Header3,
  Header5,
} from "../components/Typography"
import SectionHeader from "../components/SectionHeader"
import Section from "../components/Section"
import { SolutionsRow, Solution } from "../components/solutionsRow"
import Banner from "../components/Banner"
import { FiBarChart, FiBookOpen, FiHeart, FiUsers } from "react-icons/fi"
import MainPoint from "../components/MainPoint"
import opengraph from "../images/opengraph.png"

function AlumniRelationsPage({ data }) {
  return (
    <Layout pageTitle="Alumni Relations" image={opengraph}>
      <Banner
        text={[
          <Header1>
            The future of engagement is <Header1Accent>here</Header1Accent>
          </Header1>,
          <Header3>
            Make every alum feel that they matter by giving them the
            individualized engagement experience they deserve.
          </Header3>,
        ]}
        buttons={[
          <Button primary url="/demo">
            Request Demo
          </Button>,
        ]}
        image={data.banner}
      />

      <Section>
        <SectionHeader>What We Do</SectionHeader>
        <Header2 style={{ margin: "32px 0px 48px 0px" }}>
          Alumni engagement with a purpose
        </Header2>
        <SolutionsRow>
          <Solution>
            <div>
              <FiBarChart />
            </div>
            <Header5>Measure and Increase Engagement</Header5>
            <Body>
              To increase alumni engagement, you need to start at the individual
              level. Personalize each alum’s experience by showing them what
              they care about.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiHeart />
            </div>
            <Header5>Provide Value to Alumni</Header5>
            <Body>
              Provide lifelong value for your alumni community by giving them a
              dynamic engagement experience that adapts to each stage of their
              professional and personal lives.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiUsers />
            </div>
            <Header5>Foster Alumni-to-Student Relations</Header5>
            <Body>
              Each alum has a unique experience that students can learn from.
              Provide students and alumni a scalable mentoring experience with
              the ability to crowdsource insights and wisdom from experienced
              alumni.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiBookOpen />
            </div>
            <Header5>Collect Impactful Alumni Stories</Header5>
            <Body>
              Leverage 1,000+ prompts tailored to your institution and
              specifically designed to elicit authentic alumni stories. Help
              your alumni reconnect, share their school pride, and showcase what
              binds them to their alma mater.
            </Body>
          </Solution>
        </SolutionsRow>
      </Section>
      <Section id="engagement">
        <MainPoint
          style={{ border: "1px solid var(--base050)", padding: 32 }}
          text={[
            <Header2>Alumni engagement</Header2>,
            <Body>
              The future of engagement is mass personalization, not
              'one-size-fits-all'. Our engagement solution automatically adapts
              over time to fine-tune content that is relevant to each alum's
              current stage of life.
            </Body>,
            <Body>
              Provide lifelong value to each alum with a personalized engagement
              experience where they can share memories and wisdom with students
              and their fellow alumni.
            </Body>,
            <Button primary style={{ justifySelf: "start" }} url="/engagement">
              See More on Engagement
            </Button>,
          ]}
          image={data.alumniEngagement1}
        />
      </Section>
      <Section id="event-management">
        <MainPoint
          reversed
          style={{ border: "1px solid var(--base050)", padding: 32 }}
          text={[
            <Header2>Event management</Header2>,
            <Body>
              Simplify the creation, promotion, and management of your events
              with an intuitive Event Management module that is designed to also
              help with your advancement goals.
            </Body>,
            <Body>
              Extensive interactive reports help you to exceed your attendance
              and financial goals with each event. All while automating
              processes to free up yout time.
            </Body>,
            <Button
              primary
              style={{ justifySelf: "start" }}
              url="/eventmanagement"
            >
              Learn How
            </Button>,
          ]}
          image={data.eventManagement1}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    banner: allFile(
      filter: {
        relativePath: {
          eq: "alumni-relations/surface-HJgaV1qjHS0-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    alumniEngagement1: allFile(
      filter: {
        relativePath: {
          eq: "alumni-relations/roberto-nickson-smJ6XsYy8gA-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    alumniEngagement2: allFile(
      filter: {
        relativePath: {
          eq: "alumni-relations/antenna-ZDN-G1xBWHY-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    eventManagement1: allFile(
      filter: {
        relativePath: {
          eq: "alumni-relations/keith-luke-GUAcpXPyFRc-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    eventManagement2: allFile(
      filter: {
        relativePath: {
          eq: "alumni-relations/sebastian-coman-photography-nQqNjfOVvrs-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
  }
`

export default AlumniRelationsPage
